<template>
  <div class="p-4">
    <b-row class="mb-3">
      <b-col cols="12" lg="auto">
        <h3 class="font-weight-bold my-auto" >{{ (searchStringProp != '')? 'Suche' : (selectedCategory == 0)? "Katalog" : categories.filter(e => e.id == selectedCategory)[0].name }}</h3>
        <p>{{ (selectedCategory != 0)? categories.filter(e => e.id == selectedCategory)[0].description : '' }}</p>
      </b-col>
      <b-col class="search-box d-flex" cols="12" lg="auto">
        <b-form-input type="search" class="h-100" v-model="searchStringProp" debounce="500" placeholder="Bilder suchen"></b-form-input>
        <b-button class="mr-0"><b-icon-search style="vertical-align: sub" @click="searchImage(searchString)"/></b-button>  
      </b-col>
    </b-row>

    <b-alert v-if="error" class="m-3 text-center" variant="danger" show>
      {{error}}
    </b-alert>
    <template v-else>
      <!-- Category View if there are subcategories available and not searching -->
      <b-list-group v-if="(categories.filter(e => e.parentCategory == selectedCategory).length > 0 || gettingCategories) && !isSearching" style="position: relative">
        <div v-if="categories.length == 0" class="thumb-spinner d-flex justify-content-center" style="position: relative">
          <b-spinner class="align-self-center" variant="primary"></b-spinner>
        </div>
        <b-list-group-item v-else v-for="category in categories.filter(e => e.parentCategory == selectedCategory).sort(sortByName)" :key="category.id" href="#"
          class="category-item d-flex align-items-center" @click="selectCategory(category.id)">
          <div class="icon-wrapper flex-shrink-0">
            <div class="thumb-spinner d-flex justify-content-center">
              <b-spinner class="align-self-center" variant="primary"></b-spinner>
            </div>
            <b-icon-folder-fill v-if="category.thumbLocation == ''" class="category-icon"/>
            <div v-else class="category-icon shadow-sm" :style="{ backgroundImage: `url(${category.thumbLocation})` }" ></div>
          </div>
          <div class="ml-3">
            <h6 class="mb-0 font-weight-bold">{{category.name}}</h6>
            <small v-if="category.description" class="d-inline-block mt-1" style="line-height: 1.2">
              {{category.description}}
            </small>
          </div>
        </b-list-group-item>
      </b-list-group>

      <!-- Image view if there are no subcategories available -->
      <b-row v-else class="mx-auto" align-h="center">
        <div v-if="gettingImages" class="thumb-spinner d-flex justify-content-center" style="position: relative">
          <b-spinner class="align-self-center" variant="primary"></b-spinner>
        </div>
        <div v-else-if="images.length == 0" class="thumb-spinner d-flex justify-content-center" style="position: relative">
          <p>Keine Bilder gefunden</p>
        </div>
        <b-col v-else v-for="image in images" :key="image.id" cols="auto" 
          class="d-flex align-items-center flex-column mb-1 pb-2 pt-3 image-item" 
          @click="showImageModal(image.id)">
          <div class="image-thumb-wrapper">
            <div class="thumb-spinner d-flex justify-content-center">
              <b-spinner class="align-self-center" variant="primary"></b-spinner>
            </div>
            <div class="image-thumb shadow" :style="{ backgroundImage: `url(${image.thumbLocation})` }"/>
          </div>
          <span class="mx-auto mt-1">{{ image.name }}</span>
        </b-col>
      </b-row>
    </template>

    <!-- Image Modal -->
    <b-modal id="image-modal" hide-footer centered
      :title="(images.filter(e => e.id == selectedImage).length == 0)? '' : images.filter(e => e.id == selectedImage)[0].name">
      <div class="h-100 w-100 position-relative" >
        <div class="thumb-spinner d-flex justify-content-center">
          <b-spinner class="align-self-center" variant="primary"></b-spinner>
        </div>
        <b-img :src="(images.filter(e => e.id == selectedImage).length == 0)? '' : images.filter(e => e.id == selectedImage)[0].fullsizeLocation" 
          class="rounded position-relative image-preview" fluid/>
      </div>
      <b-btn class="w-100 text-white mt-3" variant="primary"
        @click="showAddToCardModal = true">
        Zum Einkaufswagen hinzufügen
      </b-btn>
    </b-modal>

    <!-- Add to card modal -->
    <b-modal v-model="showAddToCardModal" centered title="Zum Einkaufswagen hinzufügen"
      ok-variant="primary text-white" :ok-disabled="!selectedSize"
      cancel-title="Abbrechen" @ok="addToCard"
      :ok-title="'Hinzufügen' + ((selectedSize)? (' (' + (sizePrice[selectedForRent][selectedSize] + ((!selectedForRent && selectedWithFrame)? framePrice[selectedSize] : 0)).toFixed(2).replace('.', ',') + '€)') : '')">
      <b-select v-model="selectedSize" :options="(selectedForRent)? sizeOptionsRent : sizeOptionsBuy" class="mb-2"/>
      <b-form-checkbox v-if="selectedSize" v-model="selectedForRent" class="my-1" :disabled="selectedSize == 'XXL'">
        Dieses Bild mieten
      </b-form-checkbox>
      <b-form-checkbox v-if="!selectedForRent && selectedSize" v-model="selectedWithFrame" 
        :disabled="selectedSize == 'S'" class="my-1">
        Mit Rahmen 
        <em v-if="selectedSize != 'S'" style="color: var(--font-color-inactive)">(+ {{ framePrice[selectedSize] }}€)</em>
      </b-form-checkbox>
      <b-alert v-if="selectedForRent" class="mt-3 mb-n1" variant="info" show>
        <b-icon-info-circle class="mr-2"/> Preis pro Monat inklusive Rahmen
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
export default {
  mounted() {
		this.categories = JSON.parse(localStorage.getItem('catalog-cats')) ?? [];

    this.fetchCategories();

    // parse url
    if(location.hash) {
      if(location.hash.substr(1, 1) == 'c') {
        this.selectCategory(parseInt(location.hash.substr(2, location.hash.length - 2)));
      } else if(location.hash.substr(1, 1) == 's') {
        this.searchStringProp = location.hash.substr(2, location.hash.length - 2);
      }
    }
      
    // this.getImages(this.selectedCategory);
  },
  data() {
    return {
      selectedCategory: 0,
      selectedImage: -1,
      // parentCategory set to 0 = no parentCategory (master category)
      categories: [],
      images: [ ],
      isSearching: false,
      gettingImages: false,
      gettingCategories: false,
      searchString: '',
      beforeSearchHash: '',
      showAddToCardModal: false,
      error: '',
      selectedForRent: false,
      selectedWithFrame: false,
      selectedCount: 1,
      selectedSize: null,
      sizeOptionsBuy: [
        { value: null, text: '-- Größe auswählen --' },
        { value: 'S', text: 'S (20cm x 30cm) (8,00€)' },
        { value: 'M', text: 'M (30cm x 40-45cm) (14,00€)' },
        { value: 'L', text: 'L (40cm x 50-60cm) (22,00€)' },
        { value: 'XL', text: 'XL (50cm x 60-75cm) (27,00€)' },
        { value: 'XXL', text: 'XXL (60cm x 75cm) (32,00€)' },
      ],
      sizeOptionsRent: [
        { value: null, text: '-- Größe auswählen --' },
        { value: 'S', text: 'S (20cm x 30cm) (3,00€)' },
        { value: 'M', text: 'M (30cm x 40-45cm) (3,50€)' },
        { value: 'L', text: 'L (40cm x 50-60cm) (4,00€)' },
        { value: 'XL', text: 'XL (50cm x 60-75cm) (5,50€)' },
      ],
      sizePrice: {
        'false': {
          'S': 8,
          'M': 14,
          'L': 22,
          'XL': 27,
          'XXL': 32,
        },
        'true': {
          'S': 3,
          'M': 3.50,
          'L': 4.00,
          'XL': 5.50,
        }
      },
      framePrice: {
        'S': 0,
        'M': 10,
        'L': 18,
        'XL': 21,
        'XXL': 25
      }
    }
  },
  methods: {
    addToCard: function() {
      this.$emit('add-cart-item', { 
        name: this.images.filter(e => e.id == this.selectedImage)[0].name, 
        size: this.selectedSize, 
        count: 1, 
        forRent: this.selectedForRent, 
        withFrame: this.selectedWithFrame 
      });

      this.selectedSize = null;
      this.selectedForRent = false;
      this.selectedWithFrame = false;
      
      this.$bvModal.hide('image-modal');
    },
    fetchCategories: async function() {
      this.gettingCategories = true;
      this.error = '';

      try {
        let result = await this.axios.get('/api/imagecategory');
        console.log(result);

        this.categories = result.data ?? [];
        this.categories = this.categories.filter(e => e.visible);

				// save cache
				localStorage.setItem('catalog-cats', JSON.stringify(this.categories));
      } catch (error) {
        console.log(error);
        this.error = 'Fehler beim Laden der Kategorien';
      }

      this.gettingCategories = false;
    },
    selectCategory: function(id) {
      this.selectedCategory = id; 
      location.hash = "c" + id;
      this.getImages(id);
    },
    getImages: async function(catId) {
      this.gettingImages = true;
      this.error = '';
      
      try {
        let result = await this.axios.get('/api/image', {
          params: {
            cat: catId
          }
        });
        console.log(result);

        this.images = result.data ?? [];
        this.images = this.images.filter(e => e.visible);
      } catch (error) {
        console.log(error);
        this.error = 'Fehler beim Laden der Bilder';
      }

      this.images.sort(this.sortByName)

      this.gettingImages = false;
    },
    sortByName: function ( a, b ) {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    },
    showImageModal: function(imageId) {
      this.$bvModal.show('image-modal');
      this.selectedImage = imageId;
    },
    searchImage: async function(string) {
      this.gettingImages = true;
      this.error = '';
      
      try {
        let result = await this.axios.get('/api/image', {
          params: {
            query: string
          }
        });
        console.log(result);

        this.images = result.data ?? [];
        this.images.sort(this.sortByName);
      } catch (error) {
        this.error = 'Bei der Suche ist leider ein Fehler aufgetreten. Bitte versuche es später erneut.'
        console.log(error);
      }

      this.isSearching = true;

      if(this.beforeSearchHash == '')
        this.beforeSearchHash = location.hash;

      location.hash = "s" + string;
      
      this.gettingImages = false;
    },
    clearSearch() {
      this.isSearching = false;

      this.beforeSearchHash = '';
      location.hash = this.beforeSearchHash;
    }
  },
  watch: {
    $route: function(to) {
      window.scroll({ x: 0, y: 0, behavior: 'smooth' });

      if(!to.hash) {
        this.selectedCategory = 0;
        this.searchStringProp = '';
        return; 
      }

      if(location.hash.substr(1, 1) == 'c') {
        this.selectCategory(parseInt(location.hash.substr(2, location.hash.length - 2)));
      } else if(location.hash.substr(1, 1) == 's') {
        this.searchImage(location.hash.substr(2, location.hash.length - 2));
      }
    }
  },
  computed: {
    searchStringProp: {
      get: function () {
        return this.searchString;
      },
      set: function (newValue) {
        if(newValue) {
          this.searchImage(newValue);
        }
        else {
          this.clearSearch();
        }

        this.searchString = newValue;
      }
    }
  }
}
</script>

<style scoped>
  .category-item {
    min-height: 5rem !important;
  }

  .image-thumb-wrapper {
    position: relative;
    width: 6rem;
    height: 6rem;
  }

  .image-item {
    cursor: pointer;
    border-radius: 0.25rem;
    transition: 0.15s;
  }

  .image-item:hover {
    background-color: var(--hover);
  }

  .image-thumb {
    width: 100%;
    height: 100%;

    border-radius: 0.25rem;

    background-size: cover;
    background-position: center;

    position: relative;

    z-index: 55;
  }  

  .icon-wrapper {
    position: relative;
    height: 4rem;
    width: 4rem;

    margin-left: -0.4rem;
  }

  .category-item {
    min-height: 5rem !important;
  }

  .search-box {
    height: 2.5rem;
  }

  .search-box .form-control {
    border-radius: 0.25rem 0 0 0.25rem !important;
  }

  .search-box .btn {
    border-radius: 0 !important;
  }

  .search-box .btn:last-child {
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }

  .image-preview {
    z-index: 55; 
    left: 50%;
    transform: translateX(-50%);
    min-height: 3rem;
    max-height: calc(100vh - 13rem);
  }
</style>